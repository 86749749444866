<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <p>Reorder the links to display them like this in the dropdown</p>
              </div>
              <div class="card-body">
                <ul
                  id="basic-list-group"
                  class="list-group"
                >
                  <draggable
                    v-model="favorites"
                    group="favor"
                    @start="drag=true"
                    @end="drag=false"
                    @change="saveNewOrder"
                  >
                    <li
                      v-for="link in favorites"
                      :key="link.order"
                      class="list-group-item draggable"
                    >
                      <div class="d-flex">
                        <span><i
                          data-feather="menu"
                          class="me-50"
                        /></span>
                        <div class="more-info">
                          <p class="mb-0">
                            <span class="todo-title">{{ link.name }}</span><br><small class="text-muted">{{ link.link }}</small>
                          </p>
                        </div>
                        <div class="flex-grow-1 text-end">
                          <span
                            class="dis-ib"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Go to page"
                            aria-label="Go to page"
                          ><a
                            :href="link.link"
                            target="_blank"
                            class="btn btn-icon rounded-circle btn-flat-secondary waves-effect"
                          ><i data-feather="external-link" /></a></span>
                          <span
                            class="dis-ib"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Edit link"
                            aria-label="Edit link"
                          ><a
                            class="btn btn-icon rounded-circle btn-flat-secondary waves-effect"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasQuickLinks"
                            aria-controls="offcanvasQuickLinks"
                            @click="edit(link)"
                          ><i data-feather="edit-2" /></a></span>
                          <span
                            class="dis-ib"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Delete link"
                            aria-label="Delete link"
                          ><a
                            class="btn btn-icon rounded-circle btn-flat-danger waves-effect"
                            data-bs-toggle="modal"
                            data-bs-target="#modalDeleteWithoutInput"
                            @click="remove(link.id)"
                          ><i data-feather="trash-2" /></a></span>
                        </div>
                      </div>
                    </li>
                  </draggable>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'

export default {
  components: {
    Draggable,
  },
  data() {
    return {
      title: 'Quick links',
      favorites: [],
    }
  },
  computed: {
    ...mapGetters({
      links: 'favorites/favorites',
    }),
  },
  watch: {
    links() {
      this.favorites = Object.keys(this.links).map(key => this.links[key])
    },
  },
  async mounted() {
    this.favorites = Object.keys(this.links).map(key => this.links[key])
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveNewOrder() {
      this.$store.dispatch('favorites/updateOrder', {
        favorites: this.favorites,
      }).then(() => {
        this.$toastr.success('', 'Favorites order saved successfully!')
      })
    },
    edit(favorite) {
      this.$store.dispatch('modals/toggleAddFavorites', true)
      this.$store.dispatch('favorites/selectFavorite', favorite)
    },
    remove(id) {
      this.$store.dispatch('favorites/delete', id).then(() => {
        this.$toastr.success('', 'Favorite deleted successfully!')
      })
    },
  },
}
</script>
